import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { notification, Select } from 'antd';
import moment, { Moment } from 'moment';

import Button from 'components/buttons/button';
import Dropdown from 'components/dropdown';
import DateRangePicker from 'components/date_range_picker';
import OccupancySettingsForm from 'components/search_section/occupancy_settings/occupancy_settings_form';

import routes from 'routing/routes';

import DEFAULT_OCCUPANCY_PARAMS from 'constants/default_occupancy_params';
import countries from 'constants/countries';

import buildPath from 'utils/build_path';
import dateFormatter from 'utils/date_formatter';
import setUrlParams from 'utils/set_url_params';
import isCarProperty from 'utils/is_car_property';
import isAccommodationProperty from 'utils/is_accommodation_property';

import { IPlaceOption, IPlaceOptionDetails, IPlaceOptionType } from 'components/place_settings/place_settings.types';
import PlaceSettings from 'components/place_settings/place_settings';

import styles from './main_search.module.scss';
import { IMainSearchParams, IMainSearchProps } from './main_search.types';

const getDriversAgeOptions = () => {
  const ageRange = [];
  for (let i = 18; i < 100; i++) {
    if (i < 30 || 60 < i) {
      ageRange.push({ label: i, value: i });
    } else if (i == 30) ageRange.push({ label: '30-60', value: 30 });
  }
  return ageRange;
};

const MainSearch: React.FC<IMainSearchProps> = ({
  propertyType,
  loadDataList,
  clearDataList,
  dataList,
  isLoading,
  locked,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const isMobile = window.innerWidth <= 640;
  const isCar = useMemo(() => isCarProperty(propertyType), [propertyType]);

  const defaultCheckinDate = isCar ? moment().add(5, 'days').set({ hour: 10, minute: 0, second: 0 }) : null;
  const defaultCheckoutDate = isCar ? moment().add(12, 'days').set({ hour: 10, minute: 0, second: 0 }) : null;

  const [isOccupancyDropdownOpen, setIsOpenOccupancyDropdown] = useState(false);
  const [checkinDate, setCheckinDate] = useState(defaultCheckinDate);
  const [checkoutDate, setCheckoutDate] = useState(defaultCheckoutDate);
  const [occupancyParams, setOccupancyParams] = useState(DEFAULT_OCCUPANCY_PARAMS);
  const [placeFilter, setPlaceFilter] = useState<string | null>('');
  const [placeFilterType, setPlaceFilterType] = useState<IPlaceOptionType>(null);
  const [showHelperMessage, toggleShowHelperMessage] = useState<boolean>(false);

  const [driverCountry, setDriverCountry] = useState('IS');
  const [driverAge, setDriverAge] = useState(30);

  const handleDatesChange = useCallback((dates: [Moment | null, Moment | null] | null | undefined) => {
    setCheckinDate(dates?.[0] || null);
    setCheckoutDate(dates?.[1] || null);
  }, []);

  useEffect(() => {
    if (locked) return;

    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    loadDataList().catch((error: any) =>
      notification['error']({
        message: t('general.error_message'),
        description: error.message,
      }),
    );
  }, []);

  const driverDetails = (
    <div className={styles.driverDetails}>
      <div>
        <span className={styles.label}>{`${t('general.drivers_age')}:`}</span>
        <Select
          showSearch
          placeholder={t('general.drivers_age')}
          optionFilterProp="label"
          filterOption={true}
          options={getDriversAgeOptions()}
          allowClear
          defaultValue={driverAge}
          onChange={setDriverAge}
        />
      </div>
      <div className={styles.driverResidency}>
        <span className={styles.label}>{`${t('payment_page.payment_form.guest_info.nationality')}:`}</span>
        <Select
          showSearch
          placeholder={t('general.country')}
          optionFilterProp="label"
          filterOption={true}
          options={countries}
          allowClear
          defaultValue={driverCountry}
          onChange={setDriverCountry}
        />
      </div>
    </div>
  );

  const handleToggleOccupancyDropdown = useCallback(() => {
    setIsOpenOccupancyDropdown(!isOccupancyDropdownOpen);
  }, [isOccupancyDropdownOpen]);

  const handleChangeOccupancy = useCallback(
    (value: number[], name: string) => {
      const params = { ...occupancyParams, [name]: value };
      setOccupancyParams(params);
    },
    [occupancyParams],
  );

  const getDropdownTitle = useCallback(() => {
    const title = `${occupancyParams.adults} ${t('hotel_page.adults')} ·
    ${occupancyParams.children} ${t('hotel_page.children')}`;

    return title;
  }, [t, occupancyParams]);

  const dropdownTitle = getDropdownTitle();

  const onSearch = () => {
    if (isSearchDisabled) {
      toggleShowHelperMessage(true);
      return;
    }
    toggleShowHelperMessage(false);
    const formattedDates: { checkinDate?: string | null; checkoutDate?: string | null } = {};
    let params: IMainSearchParams = { type: propertyType };
    if (isAccommodationProperty(propertyType)) {
      if (checkinDate) {
        formattedDates.checkinDate = dateFormatter.toApi(checkinDate);
      }

      if (checkoutDate) {
        formattedDates.checkoutDate = dateFormatter.toApi(checkoutDate);
      }

      params = { ...formattedDates, ...occupancyParams, ...params };
    }

    if (isCar) {
      params.driverAge = driverAge;
      params.driverCountry = driverCountry;
    }

    if (placeFilterType === 'property') {
      const property = dataList?.['property']?.find((property: IPlaceOptionDetails) => property.title === placeFilter);

      if (property) {
        setUrlParams(params, history);
        clearDataList();

        const propertyPagePath = buildPath(history.location.search, routes.propertyPage, { channelId: property?.id });
        return history.push(propertyPagePath);
      }
    }

    if (placeFilter && placeFilterType) {
      if (placeFilterType === 'country') {
        params[placeFilterType] = countries.find(c => c.label === placeFilter)?.value;
      } else if (placeFilterType === 'property') {
        params['title'] = placeFilter;
      } else {
        params[placeFilterType] = placeFilter;
      }
    }

    if (isCar && checkinDate && checkoutDate) {
      params.pickupDate = dateFormatter.toApi(checkinDate);
      params.dropoffDate = dateFormatter.toApi(checkoutDate);
      params.pickupTime = dateFormatter.toTime(checkinDate);
      params.dropoffTime = dateFormatter.toTime(checkoutDate);
    }

    setUrlParams(params, history);
    clearDataList();
    const searchPagePath = buildPath(history.location.search, routes.searchPage);
    return history.push(searchPagePath);
  };

  const handleSelectPlaceOption = (value: string, option: IPlaceOption) => {
    setPlaceFilter(value);
    setPlaceFilterType(option.type);
  };

  const isSearchDisabled = useMemo(() => !(checkinDate && checkoutDate), [checkinDate, checkoutDate]);

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <PlaceSettings
          onSelectOption={handleSelectPlaceOption}
          propertiesData={dataList}
          isLoading={isLoading}
          searchTerm={placeFilter}
          {...(isCar && { onSearch: loadDataList })}
        />
        <DateRangePicker
          onCalendarChange={handleDatesChange}
          bordered={true}
          className={styles.range_picker}
          renderNumOfNights={true}
          type={propertyType}
          disablePastDates={true}
          showTime={isCar}
          defaultValue={[checkinDate, checkoutDate]}
        />

        {isCar && isMobile && driverDetails}

        {isAccommodationProperty(propertyType) && (
          <Dropdown
            show={isOccupancyDropdownOpen}
            onToggle={handleToggleOccupancyDropdown}
            title={dropdownTitle}
            className={styles.occupancyDropDown}
            layout="vertical"
          >
            <OccupancySettingsForm
              bookingParams={occupancyParams}
              onClose={handleToggleOccupancyDropdown}
              onChange={handleChangeOccupancy}
            />
          </Dropdown>
        )}

        <Button onClick={onSearch} className={styles.searchBtn} disabled={locked}>
          {t('main_page.search_btn')}
        </Button>
      </div>
      {showHelperMessage && <div className={styles.helperMessage}>{t('general.select_search_dates_message')}</div>}
      {isCar && !isMobile && driverDetails}
    </div>
  );
};

export default MainSearch;
