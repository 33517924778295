import React, { useContext, useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';

import Loading from 'components/loading';
import GiftCardStoreDetails from 'components/gift_card_store_details/gift_card_store_details';
import { IGiftCard } from 'components/gift_card_store_details/gift_card_store_details.types';

import { PropertyGiftCardsActionsContext, PropertyGiftCardsDataContext } from 'containers/data_context';

import styles from './gift_card_store_detail_page.module.scss';
import PageTitle from 'components/page_title/page_title';

const GiftCardStoreDetailPage: React.FC = () => {
  const { t } = useTranslation();

  const { selectedProperty, giftCardId } = useParams<{ selectedProperty: string; giftCardId: string }>();

  const propertyGiftCardsActions = useContext(PropertyGiftCardsActionsContext);

  const [stock, setStock] = useState<number>(0);
  const [redirect, setRedirect] = useState(false);

  const {
    storeGiftCardTypes: { data, isLoading },
    giftCardTypeSize: { data: giftCardTypeSizeData, isLoading: giftCardTypeSizeIsLoading },
    propertyInfo: { data: property },
  } = useContext(PropertyGiftCardsDataContext);

  const [giftCardDetails, setGiftCardDetails] = useState<IGiftCard>(
    data?.find((giftCard: IGiftCard) => giftCard.id == Number(giftCardId)),
  );

  useEffect(() => {
    async function initApp() {
      try {
        await propertyGiftCardsActions.getStoreGiftCardTypes({ propertyId: selectedProperty });
        await propertyGiftCardsActions.getGiftCardTypeSize({
          giftCardTypeId: giftCardId,
          propertyId: selectedProperty,
        });
      } catch (e) {
        setRedirect(true);
        return notification['error']({
          message: 'An error occured.',
          description: 'Gift certificate not found!',
        });
      }
    }
    initApp();
  }, [propertyGiftCardsActions, selectedProperty]);

  useEffect(() => {
    if (data && giftCardTypeSizeData) {
      const giftCard = data.find((giftCard: IGiftCard) => giftCard.id == Number(giftCardId));
      const value = giftCardTypeSizeData.size;
      setGiftCardDetails(giftCard);
      setStock(value);
    }
  }, [data, giftCardTypeSizeData]);

  const handleBuildPath = (path: string) => buildPath('', path, { selectedProperty });

  if (redirect) {
    const redirectPath = buildPath('', routes.giftCardStorePage, { selectedProperty });
    return <Redirect to={redirectPath} />;
  }

  return (
    <div className={styles.root} data-testid="GiftCardStoreDetailPage">
      {isLoading || giftCardTypeSizeIsLoading ? (
        <Loading />
      ) : giftCardDetails ? (
        <>
          <PageTitle
            steps={[
              { title: t('general.home'), link: handleBuildPath(routes.giftCardStorePage) },
              { title: t('gift_card') },
              { title: `${giftCardDetails.title}` },
            ]}
          />
          <GiftCardStoreDetails giftCard={giftCardDetails} stock={stock} storeSettings={property?.storeSetting} />
        </>
      ) : null}
    </div>
  );
};

export default GiftCardStoreDetailPage;
