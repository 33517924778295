// Generated with util/create-component.js
import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Drawer, notification, Tabs } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import GIFT_CAMPAIGN_STATUS from 'constants/gift_campaign_status';
import DRAWER_SIZE from 'constants/drawer_size';
import DATE_FORMATTER from 'utils/date_formatter';

import { GiftCampaignActionsContext, GiftCampaignDataContext } from 'containers/data_context';

import GiftCampaignsList from 'components/gift_campaigns_list/gift_campaigns_list';
import { IPaginationParams } from 'components/gift_campaigns_list/gift_campaigns_list.types';
import { IGiftCampaign } from 'components/gift_campaign_form/gift_campaign_form.types';
import GiftCampaignForm from 'components/gift_campaign_form/gift_campaign_form';
import { IGiftCampaignOption } from 'components/gift_campaign_option/gift_campaign_option.types';

import styles from './gift_campaigns_page.module.scss';

const DEFAULT_PAGINATION = {
  page: 1,
  limit: 100,
};

const GiftCampaignsPage: React.FC = () => {
  const { t } = useTranslation();
  const {
    getGiftCampaigns,
    createGiftCampaign,
    updateGiftCampaign,
    createGiftCampaignOption,
    updateGiftCampaignOption,
  } = useContext(GiftCampaignActionsContext);

  const {
    giftCampaigns: { data: giftCampaignsData, isLoading: isLoadingGiftCampaigns },
    isCreating,
    isUpdating,
  } = useContext(GiftCampaignDataContext);

  const [editedGC, setEditedGC] = useState<IGiftCampaign | null>(null);
  const [isGCFormOpened, setIsGCFormOpened] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(GIFT_CAMPAIGN_STATUS.PUBLISHED);
  const [pagination, setPagination] = useState<IPaginationParams>(DEFAULT_PAGINATION);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getGiftCampaigns(pagination);
      } catch (error) {
        console.error('Error fetching gift campaigns:', error);
      }
    };

    fetchData();
  }, [pagination, getGiftCampaigns]);

  const createdGC = useMemo(() => {
    if (!giftCampaignsData?.data?.length) return [];
    return giftCampaignsData?.data.filter((gc: IGiftCampaign) => gc.status === GIFT_CAMPAIGN_STATUS.CREATED);
  }, [giftCampaignsData?.data]);

  const publishedGC = useMemo(() => {
    if (!giftCampaignsData?.data?.length) return [];
    return giftCampaignsData?.data.filter((gc: IGiftCampaign) => gc.status === GIFT_CAMPAIGN_STATUS.PUBLISHED);
  }, [giftCampaignsData?.data]);

  const archivedGC = useMemo(() => {
    if (!giftCampaignsData?.data?.length) return [];
    return giftCampaignsData?.data.filter((gc: IGiftCampaign) => gc.status === GIFT_CAMPAIGN_STATUS.ARCHIVED);
  }, [giftCampaignsData?.data]);

  const handleUpdateGiftCampaign = async (giftCampaignDetails: {
    giftCampaignId: IGiftCampaign['publicId'];
    giftCampaign: IGiftCampaign;
  }) => {
    try {
      await updateGiftCampaign(giftCampaignDetails);
      openNotificationWithIcon('success');
      await getGiftCampaigns(pagination);
    } catch (e) {
      console.error(e, 'error');
      openNotificationWithIcon('error');
    }
  };

  const handlePageChange = (pagination: IPaginationParams) => {
    setPagination(pagination);
  };

  const handleOpenGCForm = (giftCampaign: IGiftCampaign | null = null) => {
    const giftCampaignFormatted = JSON.parse(JSON.stringify(giftCampaign));
    if (giftCampaignFormatted?.giftOptions?.length) {
      giftCampaignFormatted.giftOptions = giftCampaign?.giftOptions.map((go: IGiftCampaignOption) => {
        return {
          ...go,
          expirationDate: go?.expirationDate ? moment(go?.expirationDate) : null,
          photos: go.photo ? [{ thumbUrl: go.photo }] : [],
        };
      });
    }

    setEditedGC(giftCampaignFormatted);
    setIsGCFormOpened(true);
  };

  const handleCloseGCForm = () => {
    setEditedGC(null);
    setIsGCFormOpened(false);
  };

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('general.success_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleSaveGiftOption = async (
    giftOption: IGiftCampaignOption,
    giftCampaignId: IGiftCampaign['publicId'] = '',
  ) => {
    const formData = new FormData();

    Object.keys(giftOption).map(key => {
      if (['name', 'description', 'photo', 'expirationDate'].includes(key)) {
        formData.append(
          `gift_option[${key.replace(/[A-Z]/g, (char: string) => `_${char.toLowerCase()}`)}]`,
          giftOption[key as keyof IGiftCampaignOption] as string | Blob,
        );
      }
    });

    if (giftOption.publicId) {
      await updateGiftCampaignOption({
        giftOptionId: giftOption.publicId,
        giftCampaignId: editedGC?.publicId,
        giftOption: formData,
      });
    } else {
      await createGiftCampaignOption({ giftCampaignId, giftOption: formData });
    }
  };

  const handleSaveGC = async (giftCampaignDetails: IGiftCampaign): Promise<void> => {
    const { giftOptions, ...giftCampaign } = giftCampaignDetails;
    try {
      let giftCampaignId = editedGC ? editedGC.publicId : '';
      if (editedGC) {
        if (JSON.stringify(editedGC) !== JSON.stringify(giftCampaign)) {
          await updateGiftCampaign({ giftCampaignId: giftCampaign.publicId, giftCampaign });
        }
      } else {
        const data = await createGiftCampaign(giftCampaign);
        giftCampaignId = data.publicId;
      }

      const formattedGiftOptions = giftOptions.map((go: IGiftCampaignOption) => {
        return {
          ...(go.publicId && { publicId: go.publicId }),
          name: go.name,
          description: go.description,
          photo: go.photos?.[0]?.originFileObj ? go.photos?.[0]?.originFileObj : go.photo ? go.photo : '',
          expirationDate: DATE_FORMATTER.toApi(go.expirationDate) || '',
        };
      });

      const updatedGiftOptions = formattedGiftOptions.filter((go: IGiftCampaignOption) => {
        if (!go.publicId) return go;
        const originalGO = editedGC?.giftOptions.find((g: IGiftCampaignOption) => g.publicId === go.publicId);
        if (originalGO) {
          const formattedGO = {
            publicId: originalGO.publicId,
            name: originalGO.name,
            description: originalGO.description,
            photo: originalGO.photos?.[0]?.originFileObj
              ? originalGO.photos?.[0]?.originFileObj
              : originalGO.photo
              ? originalGO.photo
              : '',
            expirationDate: DATE_FORMATTER.toApi(originalGO.expirationDate) || '',
          };

          if (JSON.stringify(formattedGO) !== JSON.stringify(go)) return go;
        }
      });

      await Promise.all(
        updatedGiftOptions.map((giftOption: IGiftCampaignOption) => handleSaveGiftOption(giftOption, giftCampaignId)),
      );

      openNotificationWithIcon('success');
      handleCloseGCForm();

      getGiftCampaigns(pagination);
    } catch (e) {
      console.error(e);
      openNotificationWithIcon('error');
    }
  };

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
  };

  const tabs = [
    {
      label: t('gift_card.published'),
      key: 'published',
      children: (
        <GiftCampaignsList
          giftCampaigns={publishedGC}
          isLoading={isLoadingGiftCampaigns}
          pagination={giftCampaignsData?.meta}
          onChange={handlePageChange}
          onOpenGiftCampaign={handleOpenGCForm}
          onUpdateGiftCampaign={handleUpdateGiftCampaign}
        />
      ),
    },
    {
      label: t('gift_card.drafts'),
      key: 'created',
      children: (
        <GiftCampaignsList
          giftCampaigns={createdGC}
          isLoading={isLoadingGiftCampaigns}
          pagination={giftCampaignsData?.meta}
          onChange={handlePageChange}
          onOpenGiftCampaign={handleOpenGCForm}
          onUpdateGiftCampaign={updateGiftCampaign}
        />
      ),
    },
    {
      label: t('gift_card.archived'),
      key: 'archived',
      children: (
        <GiftCampaignsList
          giftCampaigns={archivedGC}
          isLoading={isLoadingGiftCampaigns}
          pagination={giftCampaignsData?.meta}
          onChange={handlePageChange}
          onOpenGiftCampaign={handleOpenGCForm}
          onUpdateGiftCampaign={updateGiftCampaign}
        />
      ),
    },
  ];

  const createButton = (
    <Button
      onClick={() => handleOpenGCForm()}
      type="primary"
      size="large"
      icon={<PlusCircleOutlined />}
      className={styles.createButton}
    >
      {t('link.create')}
    </Button>
  );

  return (
    <div data-testid="GiftCampaignsPage" className={styles.root}>
      {window.innerWidth <= 900 && (
        <Row justify="end" align="middle" className={styles.header_container} gutter={[16, 16]}>
          <Col sm={4}>{createButton}</Col>
        </Row>
      )}

      <Tabs
        activeKey={selectedTab}
        onTabClick={handleTabClick}
        items={tabs}
        tabBarExtraContent={window.innerWidth > 900 ? { right: createButton } : null}
      />
      <Drawer
        open={isGCFormOpened}
        title={editedGC ? editedGC.name : t('gift_campaign.create')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
        onClose={handleCloseGCForm}
        destroyOnClose={true}
      >
        <GiftCampaignForm
          giftCampaign={editedGC}
          isSaving={editedGC ? isUpdating : isCreating}
          onCancel={handleCloseGCForm}
          onSave={handleSaveGC}
        />
      </Drawer>
    </div>
  );
};

export default GiftCampaignsPage;
