import 'antd/dist/antd.less';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Routing from 'routing';

import HeadManager from 'components/head_manager';
import ScrollManager from 'components/scroll_manager';

import { DataContextProvider } from 'containers/data_context';

function App(): JSX.Element {
  return (
    <Suspense fallback={<div />}>
      <BrowserRouter>
        <DataContextProvider>
          <HeadManager />
          <ScrollManager />
          <Routing />
        </DataContextProvider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
