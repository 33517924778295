import React, { useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { useHistory } from 'react-router-dom';

import dateFormatter from 'utils/date_formatter';
import setUrlParams from 'utils/set_url_params';
import getUrlParams from 'utils/get_url_params';
import isCarProperty from 'utils/is_car_property';

import DateRangePicker from 'components/date_range_picker/date_range_picker';

import styles from './date_select.module.scss';
import { IDateSelectProps, IUrlParams, IDateParams } from './date_select.types';

const DateSelect: React.FC<IDateSelectProps> = ({
  bookingParams,
  closedDates,
  handleSearchChange,
  propertyType,
  closedDatesPerRate,
}) => {
  const { checkinDate, checkoutDate, pickupTime, dropoffTime } = getUrlParams() as IUrlParams;
  const history = useHistory();

  const isCar = useMemo(() => isCarProperty(propertyType), [propertyType]);

  const handleDatesChange = useCallback(
    (dates: [Moment | null, Moment | null] | null | undefined) => {
      if (!!dates?.[0] && !!dates?.[1]) {
        const formattedDates: IDateParams = {
          checkinDate: dateFormatter.toApi(dates[0]),
          checkoutDate: dateFormatter.toApi(dates[1]),
        };

        if (isCar) {
          formattedDates.pickupTime = dateFormatter.toTime(dates[0]);
          formattedDates.dropoffTime = dateFormatter.toTime(dates[1]);
        }

        setUrlParams(formattedDates, history);
        handleSearchChange({ ...bookingParams, checkinDate: dates[0], checkoutDate: dates[1] });
      }
    },
    [history, bookingParams, handleSearchChange],
  );

  const defaultValue: [Moment | null, Moment | null] = [
    checkinDate ? moment(checkinDate + ' ' + (pickupTime || '00:00')) : null,
    checkoutDate ? moment(checkoutDate + ' ' + (dropoffTime || '00:00')) : null,
  ];

  return (
    <DateRangePicker
      onCalendarChange={handleDatesChange}
      className={styles.range_picker}
      renderNumOfNights={true}
      disablePastDates={true}
      bordered={true}
      type={propertyType}
      size="large"
      defaultValue={defaultValue}
      closedDates={closedDates}
      closedDatesPerRate={closedDatesPerRate}
      showTime={isCar}
    />
  );
};

export default DateSelect;
