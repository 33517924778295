// Generated with util/create-component.js
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import { Table, Dropdown, Drawer, Button, Modal, MenuProps, notification } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import type { NotificationPlacement } from 'antd/es/notification';
import { saveAs } from 'file-saver';

import SearchInput from 'components/search_input';
import GiftCardDetails from 'components/gift_card_details/gift_card_details';
import GiftCardIssueForm from 'components/gift_card_issue_form';

import DATE_FORMATTER from 'utils/date_formatter';
import formatNumber from 'utils/format_price_locale';
import isGiftcardInventorySoldOut from 'utils/is_giftcard_invetory_sold_out';

import DRAWER_SIZE from 'constants/gift_card_drawer_size';

import { ICreatedGiftCard, ICreatedGiftCardsProps } from './created_gift_cards.types';
import { IPublishedGiftCard } from '../published_gift_cards/published_gift_cards.types';
import { IGiftCardMultipleIssueFormValues } from 'components/gift_card_issue_form/gift_card_issue_form.types';

import styles from './created_gift_cards.module.scss';
import { PropertyGiftCardsActionsContext, PropertySettingsDataContext } from '../../../containers/data_context';

const COLUMN_WIDTH = 150;

const openNotification = (placement: NotificationPlacement, downloadStarted: string, downloadDescription: string) => {
  notification.info({
    message: downloadStarted,
    description: downloadDescription,
    placement,
  });
};

const CreatedGiftCards: React.FC<ICreatedGiftCardsProps> = ({
  giftCards,
  isLoadingRatePlans,
  onUpdateGiftCard,
  onSaveChanges,
  onLoadRatePlans,
  properties,
  selectedProperty,
  groupStores,
  handleDeletePhoto,
  userHotelRoomTypeRatePlans,
  pdfTemplates,
  isLoadingPdfTemplates,
  onLoadPdfTemplates,
  filledSections,
  isLoadingFilledSections,
  onLoadFilledSections,
}) => {
  const { t } = useTranslation();
  const [createdGCList, setCreatedGCList] = useState<Array<ICreatedGiftCard>>(giftCards);
  const [showGiftCardDetails, setShowGiftCardDetails] = useState<boolean>(false);
  const [giftCard, setGiftCard] = useState<ICreatedGiftCard>();
  const [showConfirmationModal, toggleConfirmationModalVisibilty] = useState(false);
  const [isDownloadDisabled, setDownloadDisabled] = useState<boolean>(false);
  const [showGiftCardForm, setShowGiftCardForm] = useState<boolean>(false);

  const propertyGiftCardsActions = useContext(PropertyGiftCardsActionsContext);
  const propertySettingsData = useContext(PropertySettingsDataContext);

  useEffect(() => {
    setCreatedGCList(giftCards);
  }, [giftCards]);

  const openDrawer = () => {
    setShowGiftCardDetails(true);
    onLoadRatePlans();
  };

  const closeDrawer = () => {
    setShowGiftCardDetails(false);
  };

  const handleEdit = (giftCardId: ICreatedGiftCard['id']) => {
    const giftCard = createdGCList.find(giftCard => giftCard.id == giftCardId);
    setGiftCard(giftCard);
    openDrawer();
  };

  const handlePublish = (giftCardId?: ICreatedGiftCard['id']) => {
    const giftCard = createdGCList.find(giftCard => giftCard.id == giftCardId);
    if (!giftCard) return;

    const giftCardTypeParams: ICreatedGiftCard = {
      ...giftCard,
      status: 'published',
    };

    onUpdateGiftCard(giftCardTypeParams);
  };

  const showModal = (giftCard: ICreatedGiftCard) => {
    toggleConfirmationModalVisibilty(true);
    setGiftCard(giftCard);
  };

  const handleOk = async () => {
    toggleConfirmationModalVisibilty(false);
    handlePublish(giftCard?.id);
  };

  const handleCancel = () => {
    toggleConfirmationModalVisibilty(false);
  };

  const openIssueCardDrawer = (giftCard: IPublishedGiftCard) => {
    setShowGiftCardForm(true);
    setGiftCard(giftCard);
  };

  const closeIssueCardDrawer = () => {
    setShowGiftCardForm(false);
  };

  const handlePdfPreview = async (giftCard: ICreatedGiftCard) => {
    try {
      setDownloadDisabled(true);
      openNotification('bottomRight', t('gift_card.download_started'), t('gift_card.download_description'));
      const pdf = await propertyGiftCardsActions.getGiftCardDetailsPdfPreview({
        giftCardTypeId: giftCard.id,
        propertyId: selectedProperty,
      });
      saveAs(pdf, `gift_card_${giftCard?.title}_preview`);
    } catch (e) {
      openNotification('topRight', t('gift_card.download_failed'), t('gift_card.download_failed_message'));
      console.log('error', e);
    }
    setDownloadDisabled(false);
  };

  const handleIssuingMultipleGiftCard = async (giftCardId: number, issuedParams: IGiftCardMultipleIssueFormValues) => {
    await propertyGiftCardsActions.postGiftCardIssuedMultiple({
      propertyId: propertySettingsData.selectedProperty,
      giftCardId,
      issuedParams,
    });
  };

  const handleDuplicate = (giftCardId: IPublishedGiftCard['id']) => {
    const giftCard = createdGCList.find(giftCard => giftCard.id == giftCardId);
    if (giftCard) {
      setGiftCard({ ...giftCard, title: `Copy of ${giftCard.title}`, isDuplicate: true });
      openDrawer();
    }
  };

  const renderMenu = (giftCard: ICreatedGiftCard): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        label: t('gift_card.pdf_preview'),
        key: 'pdf',
        onClick: () => handlePdfPreview(giftCard),
        disabled: isDownloadDisabled,
      },
      {
        label: t('gift_card.edit_product'),
        key: 'edit',
        onClick: () => handleEdit(giftCard.id),
      },
      {
        label: 'Duplicate product',
        key: 'duplicate',
        onClick: () => handleDuplicate(giftCard.id),
      },
      {
        type: 'divider',
      },
      {
        label: t('general.publish'),
        key: 'publish',
        onClick: () => showModal(giftCard),
      },
      {
        label: t('gift_card.create_order'),
        key: 'order',
        onClick: () => openIssueCardDrawer(giftCard),
        disabled: isGiftcardInventorySoldOut(giftCard),
      },
    ];
    return { items: menuItems };
  };

  const tableColumns: ColumnsType<ICreatedGiftCard> = [
    {
      title: t('general.title'),
      dataIndex: 'title',
      key: 'title',
      width: COLUMN_WIDTH,
    },
    {
      title: t('bookings_list.price_title'),
      dataIndex: 'price',
      key: 'price',
      render: (record: ICreatedGiftCard['price']) => <div>{formatNumber(record)} kr</div>,
      width: COLUMN_WIDTH,
    },
    {
      title: t('gift_card.validity_label'),
      dataIndex: 'expirationDuration',
      key: 'expirationDuration',
      render: (expirationDuration: ICreatedGiftCard['expirationDuration']) => (
        <div>
          {expirationDuration} {expirationDuration == 1 ? t('general.month') : t('general.months')}
        </div>
      ),
      width: COLUMN_WIDTH,
    },
    {
      title: t('link.inventory'),
      dataIndex: 'inventory',
      key: 'inventory',
      width: COLUMN_WIDTH,
    },
    {
      title: t('general.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: ICreatedGiftCard['createdAt']) => <div>{DATE_FORMATTER.toUiDateTime(createdAt)}</div>,
      sorter: (b1: ICreatedGiftCard, b2: ICreatedGiftCard) =>
        moment(b1.createdAt).diff(moment(b2.createdAt), 'seconds'),
      defaultSortOrder: 'descend',
      width: COLUMN_WIDTH,
    },
    {
      title: t('general.updated_at'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt: ICreatedGiftCard['updatedAt']) => <div>{DATE_FORMATTER.toUiDateTime(updatedAt)}</div>,
      sorter: (b1: ICreatedGiftCard, b2: ICreatedGiftCard) =>
        moment(b1.updatedAt).diff(moment(b2.updatedAt), 'seconds'),
      width: COLUMN_WIDTH,
    },
    {
      key: 'actions',
      render: (text, record) => (
        <Dropdown menu={renderMenu(record)}>
          <div className={styles.actions_link}>
            <span className={styles.action_link_text}>{t('general.actions')}</span> <DownOutlined />
          </div>
        </Dropdown>
      ),
      width: COLUMN_WIDTH,
    },
  ];

  const handleSearchTermChange = useCallback(
    (term: string) => {
      const createdFiltered = giftCards.filter((giftCard: ICreatedGiftCard) =>
        giftCard.title.toLowerCase().includes(term.toLowerCase()),
      );
      setCreatedGCList(createdFiltered);
    },
    [giftCards],
  );

  return (
    <div data-testid="CreatedGiftCards" className={styles.root}>
      <SearchInput
        onSearch={handleSearchTermChange}
        placeholder={t('general.search_for_a_title')}
        className={styles.search_input}
      />
      <Table
        dataSource={createdGCList}
        columns={tableColumns}
        rowKey="id"
        pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
        data-testid="CreatedGiftCardsList"
        scroll={{ x: 'max-content', y: window.innerHeight - (window.innerWidth < 900 ? 430 : 340) }}
      />

      <Modal
        transitionName=""
        maskTransitionName=""
        open={showConfirmationModal}
        title="Are you sure?"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t('link.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {t('general.publish')}
          </Button>,
        ]}
      >
        <p>{`Are you sure you want to publish ${giftCard?.title} ?`}</p>
        <p>This will add the gift card to the store.</p>
      </Modal>

      <Drawer
        destroyOnClose
        title={giftCard?.isDuplicate ? 'Create a new product' : t('gift_card.edit')}
        open={showGiftCardDetails}
        onClose={closeDrawer}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
      >
        {giftCard && (
          <GiftCardDetails
            giftCard={giftCard}
            isLoadingRatePlans={isLoadingRatePlans}
            onSaveChanges={onSaveChanges}
            properties={properties}
            selectedProperty={selectedProperty}
            onDeletePhoto={handleDeletePhoto}
            userHotelRoomTypeRatePlans={userHotelRoomTypeRatePlans}
            groupStores={groupStores}
            pdfTemplates={pdfTemplates}
            onLoadPdfTemplates={onLoadPdfTemplates}
            isLoadingPdfTemplates={isLoadingPdfTemplates}
            filledSections={filledSections}
            onLoadFilledSections={onLoadFilledSections}
            isLoadingFilledSections={isLoadingFilledSections}
          />
        )}
      </Drawer>

      <Drawer
        destroyOnClose
        title={t('gift_card.create_order')}
        open={showGiftCardForm}
        onClose={closeIssueCardDrawer}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
      >
        {giftCard && (
          <GiftCardIssueForm
            giftCardType={giftCard}
            onCancel={closeIssueCardDrawer}
            onSaveChanges={handleIssuingMultipleGiftCard}
          />
        )}
      </Drawer>
    </div>
  );
};

export default CreatedGiftCards;
